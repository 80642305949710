import React from "react";
import Card from "react-bootstrap/Card";
// import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        {/* <blockquote className="blockquote mb-0"> */}
        <h2 style={{ textAlign: "center" }}>
          Hello, my name is <span className="teal">Sam. </span>
          <br />
        </h2>
        <p style={{ textAlign: "center" }}>
          I am a <span className="teal"> Full-Stack </span> Software Engineer.
          <span className="teal"></span>
          <br />
        </p>
        <p style={{ textAlign: "center" }}>
          I value the use of technology to
          <span className="teal">
            {" "}
            solve problems & create positive change{" "}
          </span>
          as well as the opportunity to continously learn and grow in both my
          personal and professional life.
          <br />
        </p>
        <p style={{ textAlign: "justify" }}>
          <br />
        </p>

        <p style={{ color: "rgb(155 126 172)" }}></p>
        {/* <footer className="blockquote-footer"></footer> */}
        {/* </blockquote> */}
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
